<template>
  <div class="main-content">
    <b-overlay
      :show="showOverlay"
      opacity="0.60"
      rounded="sm"
      variant="white"
      no-wrap
    ></b-overlay>
    <h2 class="mb-4">Manage Sales Orders</h2>
    <b-row>
      <b-col md="12 0">
        <b-card>
          <!-- <b-form @submit.prevent="submit" novalidate> -->
          <b-row class="no-gutters">
            <b-form-group
              class="col-md-6 "
              label="SO Number *"
              label-for="input-1"
            >
              <b-form-input
                v-model="orderNo"
                type="text"
                required
                placeholder="SO Number *"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 pl-3"
              label="Order Date *"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.deliveryDate"
                type="date"
                required
                placeholder="Order Date *"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              class="col-md-6 "
              label="Delivery Date *"
              label-for="input-1"
            >
              <b-form-input
                v-model="form.soCreatedDate"
                type="date"
                required
                placeholder="Delivery Date *"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Customer Name *"
              label-for="input-1"
              class="col-md-6 pl-3"
            >
              <b-form-select
                v-model="form.customerName"
                id="inline-form-custom-select-pref1"
                size="lg"
                placeholder="Select customer"
              >
              <b-form-select-option :value="null" disabled>Select customer</b-form-select-option>
                <b-form-select-option
                  :value="brand.id"
                  v-for="(brand, index) in customers"
                  :key="index"
                  >{{ brand.customerName }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group
              class="col-md-6 "
              label="Term & Condition"
              label-for="input-1"
            >
              <b-form-textarea
                v-model="form.termAndCondition"
                type="text"
                required
                placeholder="Term & Condition"
                size="lg"
                rows="3"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              class="col-md-6 pl-3"
              label="Notes"
              label-for="input-1"
            >
              <b-form-textarea
                v-model="form.note"
                type="text"
                required
                placeholder="Notes"
                size="lg"
                rows="3"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group
              class="col-md-6 "
              label="Scan Barcode"
              label-for="input-1"
            >
              <b-form-input
                v-model="barCode"
                type="text"
                required
                placeholder="Scan Barcode"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Currency" label-for="input-1" class="col-md-6 pl-3">
              <b-form-select
                v-model="form.currencyId"
                id="inline-form-custom-select-pref1"
                size="lg"
              >
                <b-form-select-option
                  :value="brand.id"
                  v-for="(brand, index) in currencyOptions"
                  :key="index"
                  >{{ brand.symbol }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <v-data-table
              :headers="columns"
              hide-default-footer
              :items="form.salesOrderItems"
              single-expand
              show-expand
              disable-sort
              :expanded.sync="expanded"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <b-row>
                    <b-form-group
                      label="Hardness"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient1Id"
                        label="name"
                        placeholder="Hardness"
                        taggable
                        :options="hardness"
                        @input="
                          varientSelected(
                            item.varient1Id,
                            1
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Sharpness"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient2Id"
                        label="name"
                        placeholder="Sharpness"
                        taggable
                        :options="sharpness"
                        @input="
                          varientSelected(
                            item.varient2Id,
                            2
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Color"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient3Id"
                        label="name"
                        placeholder="Color"
                        taggable
                        :options="color"
                        @input="
                          varientSelected(
                            item.varient3Id,
                            3
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Shape"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient4Id"
                        label="name"
                        placeholder="Shape"
                        taggable
                        :options="shape"
                        @input="
                          varientSelected(
                            item.varient4Id,
                            4
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Number of faces"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient5Id"
                        label="name"
                        placeholder="No of faces"
                        taggable
                        :options="nooffaces"
                        @input="
                          varientSelected(
                            item.varient5Id,
                            5
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Clarity"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient6Id"
                        label="name"
                        placeholder="Clarity"
                        taggable
                        :options="clarity"
                        @input="
                          varientSelected(
                            item.varient6Id,
                            6
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Treatment"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient7Id"
                        label="name"
                        placeholder="Treatment"
                        taggable
                        :options="treatment"
                        @input="
                          varientSelected(
                            item.varient7Id,
                            7
                          )
                        "
                      />
                    </b-form-group>

                    <b-form-group
                      label="Effect"
                      label-for="input-1"
                      class="col-md-1"
                    >
                      <v-select
                        v-model="item.varient8Id"
                        label="name"
                        placeholder="Effect"
                        taggable
                        :options="effect"
                        @input="
                          varientSelected(
                            item.varient8Id,
                            8
                          )
                        "
                      />
                    </b-form-group>
                  </b-row>
                </td>
              </template>

              <template v-slot:item.remove="{ item, index }">
                <div class="d-flex justify-content-center" v-if="index !== 0">
                  <button
                    class="btn btn-outline-primary btn-rounded"
                    type="primary"
                    size="small"
                    @click="removeProduct(item.index)"
                  >
                    X
                  </button>
                </div>
              </template>

              <template v-slot:item.product="{ item, index }">
                <b-form-group label-for="input-1">
                  <v-select
                    v-model="form.salesOrderItems[index].productId"
                    label="name"
                    placeholder="Select product"
                    taggable
                    :options="products"
                    @input="
                      productSelected(
                        form.salesOrderItems[index].productId,
                        index
                      )
                    "
                  />
                </b-form-group>
              </template>

              <template v-slot:item.warehouse="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-select
                    v-model="form.salesOrderItems[index].warehouseId"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                  >
                    <b-form-select-option
                      :value="brand.id"
                      v-for="(brand, index) in warehouseOptions"
                      :key="index"
                      >{{ brand.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </template>

              <template v-slot:item.unit="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-select
                    v-model="form.salesOrderItems[index].unitId"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                  >
                    <b-form-select-option
                      :value="brand.id"
                      v-for="(brand, index) in unitOptions"
                      :key="index"
                      >{{ brand.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </template>

              <template v-slot:item.price="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="form.salesOrderItems[index].unitPrice"
                    type="text"
                    placeholder="Price"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.transaction_currency="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-select
                    v-model="form.salesOrderItems[index].transCurrency"
                    id="inline-form-custom-select-pref1"
                    size="lg"
                  >
                    <b-form-select-option
                      :value="brand.id"
                      v-for="(brand, index) in currencyOptions"
                      :key="index"
                      >{{ brand.symbol }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </template>

              <template v-slot:item.transaction_price="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="form.salesOrderItems[index].trancPrice"
                    type="number"
                    required
                    placeholder="Transaction Price"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.quantity="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="form.salesOrderItems[index].quantity"
                    type="number"
                    required
                    placeholder="Quantity"
                    size="lg"
                    min="1"
                    @input="computeTotal(index)"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.subtotal_before_discount="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="
                      form.salesOrderItems[index].selections.bdiscount
                    "
                    type="text"
                    required
                    disabled
                    placeholder="SubTotal (Before Discount)"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.discount_percentage="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="form.salesOrderItems[index].selections.discount"
                    type="number"
                    required
                    placeholder="Discount%"
                    size="lg"
                    min="0"
                    step="5"
                    max="100"
                    @input="computeTotal(index)"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.subtotal_after_discount="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="
                      form.salesOrderItems[index].selections.adiscount
                    "
                    type="text"
                    required
                    placeholder="SubTotal (After Discount)"
                    disabled
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </template>

              <template v-slot:item.tax="{ item, index }">
                <b-form-group label-for="input-1">
                  <vue-multi-select
                    class="multi-select"
                    :ref="`multiselect-${index}`"
                    v-model="
                      form.salesOrderItems[index].salesOrderItemTaxes
                    "
                    historyButton
                    :options="taxConfigoptions"
                    @open="open(index)"
                    @close="
                      taxSelected(
                        form.salesOrderItems[index].salesOrderItemTaxes,
                        index
                      )
                    "
                    :btnLabel="btnLabel"
                    :selectOptions="taxOptions"
                    btnClass="form-control form-control-lg"
                  >
                  </vue-multi-select>
                </b-form-group>
              </template>

              <template v-slot:item.total="{ item, index }">
                <b-form-group label-for="input-1">
                  <b-form-input
                    v-model="form.salesOrderItems[index].selections.amount"
                    type="text"
                    required
                    disabled
                    placeholder="Total"
                    size="lg"
                  ></b-form-input>
                </b-form-group>
              </template>
            </v-data-table>

            <div class="w-100 m-16 d-flex justify-content-between">
              <div>
                <b-button
                  type="submit"
                  variant="primary"
                  @click="addProductSection"
                  >Add another product</b-button
                >
              </div>
              <div class="order-total d-flex">
                <div class="text-right">
                  <p class="font-weight-700">Sub Total (Before Discount):</p>
                  <p class="font-weight-700">Total Discount:</p>
                  <p class="font-weight-700">Total Tax:</p>
                  <p class="font-weight-700">Grand Total :</p>
                </div>
                <div class="text-left pl-12">
                  <p>{{ subTotalBDiscount }}</p>
                  <p>{{ totalDiscount }}</p>
                  <p>{{ totaltax }}</p>
                  <p>{{ subTotal }}</p>
                </div>
              </div>
            </div>

            <b-col md="12" class="d-flex justify-content-end">
              <b-button
                class="mt-3"
                type="submit"
                variant="primary"
                @click="submit"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import { mapGetters, mapActions } from "vuex";
import PurchaseOrderDetail from "./PurchaseOrderDetail";
import { VDataTable } from "vuetify/lib";

Vue.component("purchase-order-detail", PurchaseOrderDetail);

export default {
  metaInfo: {
    title: "Add Sales Orders",
  },
  components: {
    VDataTable,
    vueMultiSelect
  },
  data() {
    return {
      expanded: [],
      showOverlay: false,
      transCurrency: "",
      trancPrice: "",
      barCode: "",
      taxConfigoptions: {
        multi: true,
        groups: false,
      },
      form: {
        id: "",
        orderNumber: "",
        deliveryDate: "",
        deliveryStatus: 0,
        isSalesOrderRequest: false,
        soCreatedDate: "",
        salesOrderStatus: 0,
        customerName: null,
        totalAmount: 0,
        totalDiscount: 0,
        totalTax: 0,
        transTotalAmount: 0,
        transTotalDiscount: 0,
        transTotalTax: 0,
        note: "",
        termAndCondition: "",
        currencyId: "",
        salesOrderItems: [
          {
            discount: 0,
            discountPercentage: 0,
            productId: "",
            unitId: "",
            warehouseId: "",
            quantity: 0,
            taxValue: 0,
            unitPrice: 0,
            varient1Id: "",
            varient2Id: "",
            varient3Id: "",
            varient4Id: "",
            varient5Id: "",
            varient6Id: "",
            varient7Id: "",
            varient8Id: "",
            selections: {
              selectedamount: 0,
              selectedbdiscount: 0,
              selectedadiscount: 0,
              selecteddiscount: 0,
              amount: 0,
              bdiscount: 0,
              discount: 0,
              adiscount: 0,
              percentage: 0,
            },
            transCurrencyId: "SGD",
            transUnitPrice: 0,
            transTaxValue: 0,
            transDiscount: 0,
            salesOrderItemTaxes: []
          },
        ],
      },
      currencyOptions: [
        {
          id: "efbd5ee9-587b-45ef-89ba-a509f50f0f1a",
          name: "ADB Unit of Account",
          symbol: "XUA",
        },
        {
          id: "1858664e-c8fe-48fe-b4a2-72095b7678a7",
          name: "Afghan afghani",
          symbol: "AFN",
        },
        {
          id: "fbbc7fe1-ff0b-4abe-a20b-067b49e9841d",
          name: "Albanian lek",
          symbol: "ALL",
        },
        {
          id: "51942b1d-4b94-4b5f-b323-86644cd66ef7",
          name: "Algerian dinar",
          symbol: "DZD",
        },
        {
          id: "ad99fc39-7b12-41db-9591-4757ca4f742d",
          name: "Angolan kwanza",
          symbol: "AOA",
        },
        {
          id: "c89c7ced-3d48-4e5b-a753-cf1ae9aa87f2",
          name: "Argentine peso",
          symbol: "ARS",
        },
        {
          id: "e0c3c112-9919-40a8-a554-f14d7740b2b5",
          name: "Armenian dram",
          symbol: "AMD",
        },
        {
          id: "33f2b187-cfaa-4ddc-936e-2264a8f6cfd0",
          name: "Aruban florin",
          symbol: "AWG",
        },
        {
          id: "3716c421-963a-4d3f-81e6-8aa7543bbb7c",
          name: "Australian dollar",
          symbol: "AUD",
        },
        {
          id: "253eab35-b044-4158-9eec-7efc9b8e8842",
          name: "Azerbaijani manat",
          symbol: "AZN",
        },
        {
          id: "ce887e1b-764e-4363-b2f5-cfd104064f6d",
          name: "Bahamian dollar",
          symbol: "BSD",
        },
        {
          id: "d9f7017c-b2b9-44ac-b4ad-cdb198b1b186",
          name: "Bahraini dinar",
          symbol: "BHD",
        },
        {
          id: "b45d645d-1f09-4046-a777-39352194be54",
          name: "Bangladeshi taka",
          symbol: "BDT",
        },
        {
          id: "ef8dafc9-fc97-48a7-923b-5cabc46524f5",
          name: "Barbados dollar",
          symbol: "BBD",
        },
        {
          id: "f7fbf142-fc45-4828-8e75-828f1ff2aaeb",
          name: "Belarusian ruble",
          symbol: "BYN",
        },
        {
          id: "52a5f220-f165-4dc9-96aa-1605ca240e4a",
          name: "Belize dollar",
          symbol: "BZD",
        },
        {
          id: "7d3d2606-1dfc-48b0-8e77-a7b341f27ebc",
          name: "Bermudian dollar",
          symbol: "BMD",
        },
        {
          id: "52f73d1d-5d99-4e28-9631-3546b66241f8",
          name: "Bhutanese ngultrum",
          symbol: "BTN",
        },
        {
          id: "6ceeac11-67df-46b2-a2e5-90597eae9baf",
          name: "Bolivian Mvdol (funds code)",
          symbol: "BOV",
        },
        {
          id: "f61d32db-3303-4bfb-bbf3-a01e56e3fb55",
          name: "Boliviano",
          symbol: "BOB",
        },
        {
          id: "2d3346d3-3352-49c2-9929-a9e572c53372",
          name: "Bosnia and Herzegovina convertible mark",
          symbol: "BAM",
        },
        {
          id: "7078ecf5-b04f-4cf7-8ce8-9e13dcadfb3a",
          name: "Botswana pula",
          symbol: "BWP",
        },
        {
          id: "a5d89b91-66fe-42ff-99c8-27be7f0d4672",
          name: "Brazilian real",
          symbol: "BRL",
        },
        {
          id: "9a403cc3-0cc5-4d26-8ee9-3a963efa2d74",
          name: "Brunei dollar",
          symbol: "BND",
        },
        {
          id: "356ebe82-e236-4b70-b29f-1dd1aa764a52",
          name: "Bulgarian lev",
          symbol: "BGN",
        },
        {
          id: "61f3fa7c-d2cd-4a3a-abf7-4cef17bbc9a1",
          name: "Burundian franc",
          symbol: "BIF",
        },
        {
          id: "dad3f78e-7729-4b07-ab94-3bf0e677fa53",
          name: "Cambodian riel",
          symbol: "KHR",
        },
        {
          id: "cc09cf93-6439-4359-9e53-77fd56afd26b",
          name: "Canadian dollar",
          symbol: "CAD",
        },
        {
          id: "c1c47673-5b05-4651-8299-151a625b3344",
          name: "Cape Verdean escudo",
          symbol: "CVE",
        },
        {
          id: "9e0f575e-b30d-49f1-b88b-335476a349f0",
          name: "Cayman Islands dollar",
          symbol: "KYD",
        },
        {
          id: "b4aecd4c-8ace-4cb5-a893-62b7ff316aa6",
          name: "CFA franc BCEAO",
          symbol: "XOF",
        },
        {
          id: "65058c8d-cc91-4f8a-a430-fdb8dbb1bbd1",
          name: "CFA franc BEAC",
          symbol: "XAF",
        },
        {
          id: "0d097561-86b9-41b6-8946-62dba6ba2322",
          name: "CFP franc ",
          symbol: "XPF",
        },
        {
          id: "de00a01a-02eb-4d08-bd68-4f920b1faf88",
          name: "Chilean peso",
          symbol: "CLP",
        },
        {
          id: "da00b4a8-5406-466d-acd9-ff5aaece4337",
          name: "Chinese yuan[8]",
          symbol: "CNY",
        },
        {
          id: "21dc7d97-12d4-407f-9046-be98ce3c4271",
          name: "Code reserved for testing",
          symbol: "XTS",
        },
        {
          id: "0161c850-800c-442e-8b7d-e93ceda3511f",
          name: "Colombian peso",
          symbol: "COP",
        },
        {
          id: "a92a3970-5953-4afc-b162-7273bd112d28",
          name: "Comoro franc",
          symbol: "KMF",
        },
        {
          id: "7178c8cb-620a-404d-8626-5b2f93fe430d",
          name: "Congolese franc",
          symbol: "CDF",
        },
        {
          id: "9718e702-64be-45b0-bf68-19b2d0537bac",
          name: "Costa Rican colon",
          symbol: "CRC",
        },
        {
          id: "55fb491d-a70d-403b-8f42-f2954f4b6eca",
          name: "Croatian kuna",
          symbol: "HRK",
        },
        {
          id: "b09d3dfb-6162-4860-98c3-6ffcf4f38a87",
          name: "Cuban convertible peso",
          symbol: "CUC",
        },
        {
          id: "7c8b343c-cfb3-4990-b2a2-5225b9a492fd",
          name: "Cuban peso",
          symbol: "CUP",
        },
        {
          id: "f184706b-e5a7-4796-b87d-cc1a87ef8afc",
          name: "Czech koruna",
          symbol: "CZK",
        },
        {
          id: "8ef88a7f-5c30-4bfb-bbd2-c36d69a93032",
          name: "Danish krone",
          symbol: "DKK",
        },
        {
          id: "2e0be2a1-5324-4f16-b742-ed7fe1550df1",
          name: "Djiboutian franc",
          symbol: "DJF",
        },
        {
          id: "fb751fa0-e5b8-4701-9322-ea18fec10889",
          name: "Dominican peso",
          symbol: "DOP",
        },
        {
          id: "d70601d1-9872-414d-a974-eb189a2a0148",
          name: "East Caribbean dollar",
          symbol: "XCD",
        },
        {
          id: "5b523f7c-1f46-450a-8b72-3d114c8a2ead",
          name: "Egyptian pound",
          symbol: "EGP",
        },
        {
          id: "08728dcf-a885-40e0-9edb-5de7fe02a856",
          name: "Eritrean nakfa",
          symbol: "ERN",
        },
        {
          id: "a404e6a7-09b0-4911-a56c-98c43828f0fb",
          name: "Ethiopian birr",
          symbol: "ETB",
        },
        {
          id: "64e5e683-98ab-4cb8-ba89-54b2fdda9add",
          name: "Euro",
          symbol: "EUR",
        },
        {
          id: "518293b7-e9a6-4830-9b9b-d1b59a9d4408",
          name: "European Composite Unit",
          symbol: "XBA",
        },
        {
          id: "2ba1e41f-69af-4608-8f09-90d515f26ae8",
          name: "European Monetary Unit",
          symbol: "XBB",
        },
        {
          id: "a0d59bef-7483-4564-9833-2d988351bfd8",
          name: "European Unit of Account 17 ",
          symbol: "XBD",
        },
        {
          id: "65235019-070c-48aa-8751-9863304d137b",
          name: "European Unit of Account 9 ",
          symbol: "XBC",
        },
        {
          id: "1f2b643e-8625-4eed-af75-1b48ab0628d4",
          name: "Falkland Islands pound",
          symbol: "FKP",
        },
        {
          id: "335eaa64-7bac-4268-8894-b74269351dbb",
          name: "Fiji dollar",
          symbol: "FJD",
        },
        {
          id: "a7cb3225-9705-40d6-8324-5493e96e74ca",
          name: "Gambian dalasi",
          symbol: "GMD",
        },
        {
          id: "32c3368b-ba22-4e34-b3e2-a41f180196e4",
          name: "Georgian lari",
          symbol: "GEL",
        },
        {
          id: "1e462cc8-0bd3-49ff-a4b2-b736a24d872e",
          name: "Ghanaian cedi",
          symbol: "GHS",
        },
        {
          id: "aa26d093-f14f-48b3-8820-f0dd6468277e",
          name: "Gibraltar pound",
          symbol: "GIP",
        },
        {
          id: "a5b024b5-a8a0-4769-8918-0c848f80e44d",
          name: "Gold (one troy ounce)",
          symbol: "XAU",
        },
        {
          id: "b52194f1-9535-454c-ae19-1933703112fd",
          name: "Guatemalan quetzal",
          symbol: "GTQ",
        },
        {
          id: "65266ef6-5417-485d-a09d-5af0ee3e94b5",
          name: "Guinean franc",
          symbol: "GNF",
        },
        {
          id: "197af9e7-b240-47d4-9aa8-cf2778028e24",
          name: "Guyanese dollar",
          symbol: "GYD",
        },
        {
          id: "8d8a06ce-0cf8-4935-9516-a1326bbd553d",
          name: "Haitian gourde",
          symbol: "HTG",
        },
        {
          id: "943cf826-c85b-47b5-ab11-6cea4422ad3c",
          name: "Honduran lempira",
          symbol: "HNL",
        },
        {
          id: "9e65f715-d93a-436f-974c-8a5fa06ee2a7",
          name: "Hong Kong dollar",
          symbol: "HKD",
        },
        {
          id: "b3caf4f9-6045-4b2a-8234-658f6c765eed",
          name: "Hungarian forint",
          symbol: "HUF",
        },
        {
          id: "4af95b4c-afb2-41ba-b06e-768870f40a01",
          name: "Icelandic krÃ³na (plural: krÃ³nur)",
          symbol: "ISK",
        },
        {
          id: "1bdb25e6-88a6-4173-ab59-3af284db78ba",
          name: "Indian rupee",
          symbol: "INR",
        },
        {
          id: "6d0dcf44-4472-4657-ab25-f847334f907b",
          name: "Indonesian rupiah",
          symbol: "IDR",
        },
        {
          id: "7e0ede61-4d88-4f01-a622-f6fa293e3c2b",
          name: "Iranian rial",
          symbol: "IRR",
        },
        {
          id: "bf5a19d7-b4d5-4adf-9917-d769eb243e84",
          name: "Iraqi dinar",
          symbol: "IQD",
        },
        {
          id: "1b051094-f211-4dcf-82e3-77b087cc7dda",
          name: "Israeli new shekel",
          symbol: "ILS",
        },
        {
          id: "5e2655d7-93ab-4876-b4f0-17adcc86df2c",
          name: "Jamaican dollar",
          symbol: "JMD",
        },
        {
          id: "99e462cb-167b-4208-b7eb-604fb848d9de",
          name: "Japanese yen",
          symbol: "JPY",
        },
        {
          id: "83b5e6a8-8d7f-4f86-a431-f1ef42bc6ed2",
          name: "Jordanian dinar",
          symbol: "JOD",
        },
        {
          id: "7dfc1888-d6f7-49c9-be64-cedafdf163fa",
          name: "Kazakhstani tenge",
          symbol: "KZT",
        },
        {
          id: "f33dfdfa-0c58-48cd-9986-bb2da00b0a39",
          name: "Kenyan shilling",
          symbol: "KES",
        },
        {
          id: "b5d54997-3b13-4d42-9dda-6d186b86cecf",
          name: "Kuwaiti dinar",
          symbol: "KWD",
        },
        {
          id: "21e42917-2bbb-4e10-b0d1-d2b042f39df6",
          name: "Kyrgyzstani som",
          symbol: "KGS",
        },
        {
          id: "e17bbd93-c598-4c3e-8e96-cf0bb0b1f337",
          name: "Lao kip",
          symbol: "LAK",
        },
        {
          id: "d4d13f31-1d67-4510-80d7-332fec884332",
          name: "Lebanese pound",
          symbol: "LBP",
        },
        {
          id: "d4909a1e-601e-4b08-a964-e38ad79f7d69",
          name: "Lesotho loti",
          symbol: "LSL",
        },
        {
          id: "42bcfa08-3758-4d7f-a782-7c0cdd42f6bf",
          name: "Liberian dollar",
          symbol: "LRD",
        },
        {
          id: "791dbc34-6c69-4351-8563-29b5610765dd",
          name: "Libyan dinar",
          symbol: "LYD",
        },
        {
          id: "32a32049-d096-4ce9-ba96-da478bb7c953",
          name: "Macanese pataca",
          symbol: "MOP",
        },
        {
          id: "365be2be-2967-44d9-943b-5f79e6538187",
          name: "Macedonian denar",
          symbol: "MKD",
        },
        {
          id: "620101ce-f015-4be8-a2dd-0bdb0df9f5d4",
          name: "Malagasy ariary",
          symbol: "MGA",
        },
        {
          id: "2642414a-030f-49f5-b235-f3c4ea2eb532",
          name: "Malawian kwacha",
          symbol: "MWK",
        },
        {
          id: "10797893-4150-40a9-a76a-a3fda60167e6",
          name: "Malaysian ringgit",
          symbol: "MYR",
        },
        {
          id: "c65135e7-fa70-44de-91f9-b2c0264e2c8e",
          name: "Maldivian rufiyaa",
          symbol: "MVR",
        },
        {
          id: "5ecf49de-fce0-4f0e-a554-c24a95ebf9ec",
          name: "Mauritanian ouguiya",
          symbol: "MRU",
        },
        {
          id: "e8c796e5-9fde-40fe-a2de-03a5063cdf82",
          name: "Mauritian rupee",
          symbol: "MUR",
        },
        {
          id: "23cf7108-c464-458b-8a81-e2a6c265c28a",
          name: "Mexican peso",
          symbol: "MXN",
        },
        {
          id: "3624e94b-21e2-4774-9ee5-ff2e0995625e",
          name: "Mexican Unidad de Inversion (UDI) (funds code)",
          symbol: "MXV",
        },
        {
          id: "a0d62283-b722-4eb4-8c7a-6a8f13531295",
          name: "Moldovan leu",
          symbol: "MDL",
        },
        {
          id: "8a199070-0541-4ef6-ac91-2ca62273a43f",
          name: "Mongolian tÃ¶grÃ¶g",
          symbol: "MNT",
        },
        {
          id: "802b6efa-41dd-4db3-9ee7-e34101e2adb0",
          name: "Moroccan dirham",
          symbol: "MAD",
        },
        {
          id: "5a44b92f-0dcd-4837-909c-c4cf06c552ec",
          name: "Mozambican metical",
          symbol: "MZN",
        },
        {
          id: "b91dcd47-eb94-449a-a0df-244cd9dcb0f5",
          name: "Myanmar kyat",
          symbol: "MMK",
        },
        {
          id: "26765de4-2a8b-4c73-ad6b-dd2831c72a9e",
          name: "Namibian dollar",
          symbol: "NAD",
        },
        {
          id: "b2797a14-2b38-4f79-b3f5-f5d2348a1cf6",
          name: "Nepalese rupee",
          symbol: "NPR",
        },
        {
          id: "4cec274f-f5e6-4675-b876-16af784e2b8e",
          name: "Netherlands Antillean guilder",
          symbol: "ANG",
        },
        {
          id: "1c53a6eb-6458-435b-b0f3-09d9fd4dc87e",
          name: "New Taiwan dollar",
          symbol: "TWD",
        },
        {
          id: "21cb097e-c03e-4dae-94a1-0f2bcc000495",
          name: "New Zealand dollar",
          symbol: "NZD",
        },
        {
          id: "05f0463f-2e8d-49ab-a604-38c70873aabb",
          name: "Nicaraguan cÃ³rdoba",
          symbol: "NIO",
        },
        {
          id: "1aa4c8f7-7c06-4588-8f12-77c1ee162131",
          name: "Nigerian naira",
          symbol: "NGN",
        },
        {
          id: "e3affb05-1929-43db-8942-c69ceecf43c0",
          name: "North Korean won",
          symbol: "KPW",
        },
        {
          id: "ff2f1622-627b-4764-9c08-445efb1cf6c4",
          name: "Norwegian krone",
          symbol: "NOK",
        },
        {
          id: "dae3e52a-a89c-45a7-8c85-08fb8d84a441",
          name: "Omani rial",
          symbol: "OMR",
        },
        {
          id: "ae0afe64-927c-4867-a85a-40d14e1e5364",
          name: "Pakistani rupee",
          symbol: "PKR",
        },
        {
          id: "55baa4bd-2538-4580-bdb5-75e17450fd3e",
          name: "Palladium ",
          symbol: "XPD",
        },
        {
          id: "91d11e5a-faf8-4a19-a996-ba02f0461f83",
          name: "Panamanian balboa",
          symbol: "PAB",
        },
        {
          id: "a3c4792e-9e13-4016-b5d2-8b81e07b4143",
          name: "Papua New Guinean kina",
          symbol: "PGK",
        },
        {
          id: "35ac4455-fa6a-4558-bef7-bc4ab6bae9f1",
          name: "Paraguayan guaranÃ­",
          symbol: "PYG",
        },
        {
          id: "5e171260-d7ad-4f09-be98-23062f3910f4",
          name: "Peruvian sol",
          symbol: "PEN",
        },
        {
          id: "aa42be66-4431-4045-9841-a5b981754618",
          name: "Philippine peso",
          symbol: "PHP",
        },
        {
          id: "9a8facaf-faac-46dd-a294-54d882469770",
          name: "Platinum",
          symbol: "XPT",
        },
        {
          id: "8fe0c3f5-34c3-4973-b96c-91f0edf98adb",
          name: "Polish zÅ‚oty",
          symbol: "PLN",
        },
        {
          id: "2ff3fef4-9263-4edf-9bd6-da50feaeae22",
          name: "Pound sterling",
          symbol: "GBP",
        },
        {
          id: "6063a1b3-bd7e-4f34-b0ec-91b06951ea7e",
          name: "Qatari riyal",
          symbol: "QAR",
        },
        {
          id: "84d60cdb-47ec-41bd-998e-c4026ecc87a2",
          name: "Romanian leu",
          symbol: "RON",
        },
        {
          id: "cfdcae4b-c39c-4163-b877-755f4f36693d",
          name: "Russian ruble",
          symbol: "RUB",
        },
        {
          id: "55006d7d-056d-4f87-9734-cd46ab65a3dd",
          name: "Rwandan franc",
          symbol: "RWF",
        },
        {
          id: "56e9529b-7a1a-48f9-a869-d48533eb59cc",
          name: "SÃ£o TomÃ© and PrÃ­ncipe dobra",
          symbol: "STN",
        },
        {
          id: "fe2d1a0d-dd75-4add-9c9e-f69af8802736",
          name: "Saint Helena pound",
          symbol: "SHP",
        },
        {
          id: "3af80ddb-b1b1-4702-9113-c36d70af1d17",
          name: "Salvadoran colÃ³n",
          symbol: "SVC",
        },
        {
          id: "dba5b9a4-85a7-48cc-83f0-af693909c739",
          name: "Samoan tala",
          symbol: "WST",
        },
        {
          id: "fe04e052-228f-426a-bb4c-5e509017e634",
          name: "Saudi riyal",
          symbol: "SAR",
        },
        {
          id: "a77a5232-dee8-4fba-860e-ffb3fe3d71de",
          name: "Serbian dinar",
          symbol: "RSD",
        },
        {
          id: "9b8092b8-338d-4dc8-90c2-2a5cf35e63fa",
          name: "Seychelles rupee",
          symbol: "SCR",
        },
        {
          id: "67fbc0b7-b440-418e-a9bb-72a8f8a99729",
          name: "Sierra Leonean leone",
          symbol: "SLL",
        },
        {
          id: "8d843c04-9f58-43ed-9984-8f0ffa2673f7",
          name: "Silver (one troy ounce)",
          symbol: "XAG",
        },
        {
          id: "afc7ff15-5324-4984-aeb9-5579c6876be0",
          name: "Singapore dollar",
          symbol: "SGD",
        },
        {
          id: "1a89f34a-c25d-4bbc-aa3f-3532e0693f0b",
          name: "Solomon Islands dollar",
          symbol: "SBD",
        },
        {
          id: "d4b0efac-1cae-44c6-b69c-1f2c595ecb17",
          name: "Somali shilling",
          symbol: "SOS",
        },
        {
          id: "95ab9c3b-98b9-42f6-a306-94091f9f1f14",
          name: "South African rand",
          symbol: "ZAR",
        },
        {
          id: "b88d06dc-036d-48d3-818b-6d40f6194c08",
          name: "South Korean won",
          symbol: "KRW",
        },
        {
          id: "8d7030d4-aea6-47ca-8e94-6a1197de0874",
          name: "South Sudanese pound",
          symbol: "SSP",
        },
        {
          id: "c0486d0c-a874-4635-82f7-fe6f561a1976",
          name: "Special drawing rights",
          symbol: "XDR",
        },
        {
          id: "ea2c6e34-eb17-49a0-a0c3-1aea4bdd2afc",
          name: "Sri Lankan rupee",
          symbol: "LKR",
        },
        {
          id: "695a0760-54d9-49f4-894c-02694512b097",
          name: "SUCRE",
          symbol: "XSU",
        },
        {
          id: "18e37daa-bbbf-4771-8da0-11f37d538802",
          name: "Sudanese pound",
          symbol: "SDG",
        },
        {
          id: "0e50ff4a-0fc3-45e1-9d8b-5979ed0c5bdc",
          name: "Surinamese dollar",
          symbol: "SRD",
        },
        {
          id: "81409cb4-de10-4144-9f8e-6fc15667300d",
          name: "Swazi lilangeni",
          symbol: "SZL",
        },
        {
          id: "3c5e92d9-91ec-4be3-a8d8-3f00131eca56",
          name: "Swedish krona (plural: kronor)",
          symbol: "SEK",
        },
        {
          id: "dc3b14f7-fb25-4bde-8b9b-00eb532657e8",
          name: "Swiss franc",
          symbol: "CHF",
        },
        {
          id: "09332c69-b0cf-4ab8-8fa2-c7becfc59e29",
          name: "Syrian pound",
          symbol: "SYP",
        },
        {
          id: "a68d04e5-1f92-43cf-af5b-0ac3e00ab98e",
          name: "Tajikistani somoni",
          symbol: "TJS",
        },
        {
          id: "e8eb13bc-8819-4b83-bf6b-58ce2ad8eb53",
          name: "Tanzanian shilling",
          symbol: "TZS",
        },
        {
          id: "16f1f4c3-cae1-475a-8b57-de88d0e606be",
          name: "Thai baht",
          symbol: "THB",
        },
        {
          id: "d0dbf0a2-6e5b-4e50-874a-c2f610d6c8e6",
          name: "Tongan paÊ»anga",
          symbol: "TOP",
        },
        {
          id: "adbfab04-cb0a-41f3-8484-5ee1fa081c4a",
          name: "Trinidad and Tobago dollar",
          symbol: "TTD",
        },
        {
          id: "fb257e69-1991-48ca-ae20-7508134924f9",
          name: "Tunisian dinar",
          symbol: "TND",
        },
        {
          id: "f92a7677-a3ef-4f16-91b3-6bc7a1b10db4",
          name: "Turkish lira",
          symbol: "TRY",
        },
        {
          id: "c8c2a3ea-ccc3-48c4-85c8-22b0f5afcc35",
          name: "Turkmenistan manat",
          symbol: "TMT",
        },
        {
          id: "e7aefad8-3a7e-44c3-bb7b-3e80973b7f40",
          name: "Ugandan shilling",
          symbol: "UGX",
        },
        {
          id: "c54efb88-242c-4753-a531-c4fdc9d1565e",
          name: "Ukrainian hryvnia",
          symbol: "UAH",
        },
        {
          id: "3404bb86-ca75-4c64-adb9-9374d0ebc66a",
          name: "Unidad de Fomento (funds code)",
          symbol: "CLF",
        },
        {
          id: "29bd036f-5b91-46f7-9b99-08b8f7eec483",
          name: "Unidad de Valor Real",
          symbol: "COU",
        },
        {
          id: "663bcd40-24fc-4193-b4a9-5ceeac3189ff",
          name: "Unidad previsional",
          symbol: "UYW",
        },
        {
          id: "a874cfc4-eeca-4405-9f71-3fcb5276d367",
          name: "United Arab Emirates dirham",
          symbol: "AED",
        },
        {
          id: "a0330d9e-ce81-48a5-9ccf-b06227f3951b",
          name: "United States dollar",
          symbol: "USD",
        },
        {
          id: "b8cf6ee9-f714-4611-bb83-5a36ee46cf6b",
          name: "Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)",
          symbol: "UYI",
        },
        {
          id: "7bd42030-cdae-4e72-b86c-3b0cd645e510",
          name: "Uruguayan peso",
          symbol: "UYU",
        },
        {
          id: "05b108f6-dc7b-45ba-b712-e50c7fa92879",
          name: "Uzbekistan som",
          symbol: "UZS",
        },
        {
          id: "9048bcd4-8534-40ea-b065-e028e86af32d",
          name: "Vanuatu vatu",
          symbol: "VUV",
        },
        {
          id: "fcd2b0dd-32a6-4e6e-a413-46f24c27860c",
          name: "Venezuelan bolÃ­var digital",
          symbol: "VED",
        },
        {
          id: "3d22ec43-aaa8-4b43-a372-646dba5b88b1",
          name: "Venezuelan bolÃ­var soberano",
          symbol: "VES",
        },
        {
          id: "44461e7e-e291-4857-8f46-7923abe616a3",
          name: "Vietnamese Ä‘á»“ng",
          symbol: "VND",
        },
        {
          id: "cd104f48-5fab-4e11-9254-58656ba587c7",
          name: "WIR euro (complementary currency)",
          symbol: "CHE",
        },
        {
          id: "b3e437f6-268f-4f60-b7d9-45e88607e734",
          name: "WIR franc (complementary currency)",
          symbol: "CHW",
        },
        {
          id: "45a15fe3-9a85-49ef-8e46-b8dc914b9328",
          name: "Yemeni rial",
          symbol: "YER",
        },
        {
          id: "cee86834-461b-4035-9bd1-95743d7786f7",
          name: "Zambian kwacha",
          symbol: "ZMW",
        },
        {
          id: "d488cb20-543b-4410-b279-432a8ac327f3",
          name: "Zimbabwean dollar",
          symbol: "ZWL",
        },
      ],
      warehouseOptions: [
        {
          id: "f1336231-d3a9-42d0-b6d2-0f50040b0cd2",
          name: "Dpay",
          address: "PSR gold street ",
          contactPerson: "vishaly ",
          mobileNumber: "9123456789",
          email: "vishaly@gmail.com",
        },
        {
          id: "a1866bf7-d4e9-4775-8ef1-65310b00cffd",
          name: "FMM",
          address: "sannathi street,\ndharmapuram,\nkaraikal. ",
          contactPerson: "vini",
          mobileNumber: "9876543221",
          email: "vini@gmail.com",
        },
        {
          id: "f181fe8d-ae09-40ca-a790-99c1f1763aeb",
          name: "Columbo",
          address: "SL",
          contactPerson: "Kanhaiya",
          mobileNumber: "98765321245",
          email: "kanhaiya@gmail.com",
        },
        {
          id: "a1803633-2d54-4549-bad2-e49f1de69cb1",
          name: "B1Booth",
          address: "Bangkok",
          contactPerson: "Kamil",
          mobileNumber: "0805092551",
          email: "kamil@mmk.com",
        },
      ],
      unitOptions: [
        {
          id: "f3e243f5-124d-47e3-919d-2689bbfbb67b",
          name: "5 KG",
          code: "5KG",
          operator: 2,
          value: 5,
          parentId: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          baseUnitName: "Kilo Gram(KG)",
        },
        {
          id: "e9e5b90d-30a2-4af5-ac95-56710734c84b",
          name: "Box",
          code: "Box",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: "",
        },
        {
          id: "dee04e31-66cf-4949-8a7f-bb583bbca852",
          name: "Dozen",
          code: "Dozen",
          operator: 2,
          value: 12,
          parentId: "4f0a0258-0a2e-4155-8e6a-d753db215fbd",
          baseUnitName: "Piece(Piece)",
        },
        {
          id: "0251ae58-3cbd-4245-a483-4b4b97ff1304",
          name: "Gram",
          code: "GM",
          operator: 3,
          value: 1000,
          parentId: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          baseUnitName: "Kilo Gram(KG)",
        },
        {
          id: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          name: "Kilo Gram",
          code: "KG",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: "",
        },
        {
          id: "adffb238-ef09-4d67-8f99-b3594b62f63e",
          name: "Litters",
          code: "Lt",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: "",
        },
        {
          id: "4f0a0258-0a2e-4155-8e6a-d753db215fbd",
          name: "Piece",
          code: "Piece",
          operator: null,
          value: null,
          parentId: null,
          baseUnitName: "",
        },
        {
          id: "b69db658-4ac6-4cb9-b7f3-41816b088706",
          name: "Pound",
          code: "POUND",
          operator: 3,
          value: 2.2,
          parentId: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          baseUnitName: "Kilo Gram(KG)",
        },
        {
          id: "a61127d3-59b7-4e41-8968-f1c42070ab07",
          name: "Ton",
          code: "TON",
          operator: 2,
          value: 1000,
          parentId: "907c5dab-b5b1-460f-b46d-ab724fa2a4ae",
          baseUnitName: "Kilo Gram(KG)",
        },
      ],
      taxOptions: [
        {
          id: "fd3e34a9-a5e2-4e18-aed3-326cea175437",
          name: "Sales Tax",
          percentage: 10,
        },
        {
          id: "7f6fa7f1-7af8-42bc-aab9-3460c8d752bd",
          name: "SGST",
          percentage: 12,
        },
        {
          id: "3cb2058f-6a6a-4c7d-8332-3d0269d8f1fc",
          name: "THB7%",
          percentage: 7,
        },
        {
          id: "2b9972d1-300e-45b0-93e4-6d47cea9991c",
          name: "CGST",
          percentage: 10,
        },
        {
          id: "5c698e75-4dfc-408e-9318-c24a69530532",
          name: "Excise Tax",
          percentage: 8,
        },
      ],
      orderBase: {
        discount: 0,
        discountPercentage: 0,
        customerId: "",
        unitId: "",
        warehouseId: "",
        quantity: 0,
        taxValue: 0,
        unitPrice: 0,
        varient1Id: "",
        varient2Id: "",
        varient3Id: "",
        varient4Id: "",
        varient5Id: "",
        varient6Id: "",
        varient7Id: "",
        varient8Id: "",
        salesOrderItemTaxes: [],
        selections: {
          selectedamount: 0,
          selectedbdiscount: 0,
          selectedadiscount: 0,
          selecteddiscount: 0,
          amount: 0,
          bdiscount: 0,
          discount: 0,
          adiscount: 0,
          percentage: 0,
        },
      },
      columns: [
        { text: "", value: "data-table-expand" },
        {
          text: "",
          value: "remove",
          width: "95px",
        },
        {
          text: "Product",
          value: "product",
          width: "200px",
        },
        {
          text: "Warehouse",
          value: "warehouse",
          width: "200px",
        },
        {
          text: "Unit",
          value: "unit",
          width: "150px",
        },
        {
          text: "Price",
          value: "price",
          width: "130px",
        },
        {
          text: "Transaction Currency",
          value: "transaction_currency",
          width: "204px",
        },
        {
          text: "Transaction Price",
          value: "transaction_price",
          width: "140px",
        },
        {
          text: "Quantity",
          value: "quantity",
          width: "120px",
        },
        {
          text: "SubTotal (Before Discount)",
          value: "subtotal_before_discount",
          width: "210px",
        },
        {
          text: "Discount%",
          value: "discount_percentage",
          width: "100px",
        },
        {
          text: "SubTotal (After Discount)",
          value: "subtotal_after_discount",
          width: "200px",
        },
        {
          text: "Tax",
          value: "tax",
          width: "300px",
        },
        {
          text: "Total",
          value: "total",
          width: "150px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "products",
      "customers",
      "orderNo",
      "hardness",
      "sharpness",
      "color",
      "shape",
      "nooffaces",
      "clarity",
      "treatment",
      "effect",
    ]),
    subTotal() {
      let amnt = 0;
      this.form.salesOrderItems.map((v) => (amnt += v.selections.amount));
      return amnt;
    },
    totaltax() {
      let tax = 0;
      this.form.salesOrderItems.map((v) => {
        tax += v.selections.amount - v.selections.adiscount;
      });
      return tax;
    },
    totalDiscount() {
      let disc = 0;
      this.form.salesOrderItems.map(
        (v) => (disc += parseInt(v.selections.discount))
      );
      return disc;
    },
    subTotalBDiscount() {
      let amnt = 0;
      this.form.salesOrderItems.map((v) => (amnt += v.selections.bdiscount));
      return amnt;
    },
  },
  methods: {
    ...mapActions([
      "addProduct",
      "addSalesOrder",
      "getCustomers",
      "getProducts",
      "getPurchaseOrderNumber",
      "addVendor",
      "getHardness",
      "getSharpness",
      "getColor",
      "getShape",
      "getNumberofFaces",
      "getClarity",
      "getTreatment",
      "getEffect",
      "addVarient"
    ]),
    filterByPhone(options, search) {
      return options.filter((option) => {
        let label = (option.mobileNo || "") + " " + (option.supplierName || "");
        return (
          label.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        );
      });
    },
    filterBy(option, label, search) {
      return (
        (label || "").toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) >
        -1
      );
    },
    onCellClicked(data, field, event) {
      console.log("cellClicked: ", field);
      this.$refs.vuetable.toggleDetailRow(data.id);
    },
    vendorSelected(value) {
      if (value) {
        if (typeof value === "object" && !value.id) {
          this.showOverlay = true;
          let payload = {
            id: null,
            supplierName: value.supplierName,
            contactPerson: "",
            mobileNo: "",
            phoneNo: "",
            website: "",
            description: "",
            email: "testing@gmail.com",
            url: "",
            isVarified: true,
            isUnsubscribe: false,
            supplierProfile: "",
            supplierAddress: {
              id: "",
              address: "",
              cityName: "",
            },
            billingAddress: {
              id: "",
              address: "",
              cityName: "",
            },
            shippingAddress: {
              id: "",
              address: "",
              cityName: "",
            },
            surName: "",
            taxNo: "",
            proofNo: "",
            proofTypeId: "",
            logo: "",
            isImageUpload: false,
            proofUrl: "",
            isDocUpload: false,
          };
          this.addVendor(payload)
            .then(async (c) => {
              this.showOverlay = false;
              this.$bvToast.toast("Vendor added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              this.showOverlay = false;
              await this.getVendors(
                "fields=&orderBy=&pageSize=1000&skip=0&searchQuery=&name=&id=&categoryId=&unitId=&barcode=&brandId="
              );
            })
            .catch((err) => {
              this.showOverlay = false;
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 5000,
              });
            });
        }
      }
    },
    productSelected(value, index) {
      if (value) {
        if (typeof value === "object" && !value.id) {
          this.showOverlay = true;
          let payload = {
            id: value.id,
            name: value.name,
            code: "",
            barcode: "",
            skuCode: "",
            skuName: "",
            description: "",
            productUrl: null,
            qrCodeUrl: null,
            unitId: "e9e5b90d-30a2-4af5-ac95-56710734c84b",
            purchasePrice: null,
            salesPrice: null,
            mrp: null,
            categoryId: "cb44219c-ad95-4ef7-bbe2-4a5fa0285962",
            categoryName: null,
            unitName: null,
            brandId: "ee1fca5a-a9b9-4269-66d0-08da3b2a1d41",
            warehouseId: null,
            warehouseName: null,
            brandName: null,
            unit: null,
            productTaxes: [],
            varient1Id: null,
            varient1Name: null,
            varient2Id: null,
            varient2Name: null,
            varient3Id: null,
            varient3Name: null,
            varient4Id: null,
            varient4Name: null,
            varient5Id: null,
            varient5Name: null,
            varient6Id: null,
            varient6Name: null,
            varient7Id: null,
            varient7Name: null,
            varient8Id: null,
            varient8Name: null,
            createdDate: "2023-05-02T21:54:42.4931268Z",
          };
          this.addProduct(payload)
            .then(async (c) => {
              this.showOverlay = false;
              this.$bvToast.toast("Product added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              this.showOverlay = false;
              await this.getProducts(
                "fields=&orderBy=&pageSize=1000&skip=0&searchQuery=&name=&id=&categoryId=&unitId=&barcode=&brandId="
              );
              this.form.salesOrderItems[index].productId = c;
            })
            .catch((err) => {
              this.showOverlay = false;
              this.$bvToast.toast(err, {
                title: "Error",
                variant: "danger",
                autoHideDelay: 5000,
              });
            });
        } else {
          let selectedPrdct = this.products.find((v) => v.id === value.id);
          this.form.salesOrderItems[index].selections.selectedProductAmnt =
            selectedPrdct.mrp;

          this.form.salesOrderItems[index].unitPrice = selectedPrdct.mrp
            ? selectedPrdct.mrp
            : 0;
          this.form.salesOrderItems[index].quantity = 1;
          this.form.salesOrderItems[index].selections.bdiscount =
            selectedPrdct.mrp;
          this.form.salesOrderItems[index].selections.adiscount =
            selectedPrdct.mrp;
          this.form.salesOrderItems[index].selections.amount =
            selectedPrdct.mrp;

          this.form.salesOrderItems[index].selections.selectedamount =
            selectedPrdct.mrp;
        }
      }
    },
    btnLabel(values) {
      let placeholder = values.map((v) => v.name).toString();
      return placeholder ? placeholder.replaceAll(",", ", ") : "Tax";
    },
    open() {
      let id = `multiselect-${arguments[0]}`;
      const currntEl = this.$refs[id].$el;
      const { top, left } = currntEl.childNodes[0].getBoundingClientRect();
      var nextEl = document.getElementsByClassName('checkboxLayer')[arguments[0]]
      nextEl.style.position = "fixed";
      setTimeout(() => {
        nextEl.style.left = (parseInt(left)) + 'px';
        nextEl.style.top = (parseInt(top) + 50) + 'px';
      }, 0)
    },
    close() {
      console.log(arguments);
    },
    submit() {
      if (this.form.salesOrderItems[0].salesOrderItemTaxes.length > 0) {
        delete this.form["currencyId"];
        this.form.deliveryDate = "2023-03-30T18:30:00.000Z";
        this.form.soCreatedDate = "2023-03-13T18:30:00.000Z";
      }

      this.form.orderNumber = this.orderNo;
      this.form.salesOrderItems.map((v, index) => {
        delete v["selections"];
        v.productId = v.productId.id ? v.productId.id : "";
        v.salesOrderItemTaxes = v.salesOrderItemTaxes.map(function (
          value
        ) {
          return { taxId: value["id"] };
        });
      });
      this.form.customerId = this.form.customerName

      //form cleanup
      this.form.totalAmount = this.subTotal;
      this.form.totalDiscount = this.totalDiscount;
      this.form.totalTax = this.totaltax;

      this.showOverlay = true;
      this.addSalesOrder(this.form)
        .then((c) => {
          this.showOverlay = false;
          this.$bvToast.toast("Sales order added successfully", {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$router.push("/sales-order");
        })
        .catch((err) => {
          this.showOverlay = false;
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          });
        });
    },
    uploadProof() {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        vm.form.isProductImageUpload = true;
        vm.form.productUrlData = reader.result;
      };
    },
    productUpdated(id, index) {
      let selectedPrdct = this.products.find((v) => v.id === id);
      this.form.salesOrderItems[index].selections.selectedProductAmnt =
        selectedPrdct.mrp;

      this.form.salesOrderItems[index].unitPrice = selectedPrdct.mrp;
      this.form.salesOrderItems[index].quantity = 1;
      this.form.salesOrderItems[index].selections.bdiscount =
        selectedPrdct.mrp;
      this.form.salesOrderItems[index].selections.adiscount =
        selectedPrdct.mrp;
      this.form.salesOrderItems[index].selections.amount = selectedPrdct.mrp;

      this.form.salesOrderItems[index].selections.selectedamount =
        selectedPrdct.mrp;
    },
    taxSelected() {
      var percentage = 0;
      arguments[0].map((c) => {
        percentage = percentage + c.percentage;
      });
      this.form.salesOrderItems[arguments[1]].selections.percentage =
        percentage;
      this.computeTotal(arguments[1]);
    },
    computeTotal(index) {
      let totalWithQty =
        this.form.salesOrderItems[index].selections.selectedamount *
        this.form.salesOrderItems[index].quantity;
      this.form.salesOrderItems[index].selections.amount = totalWithQty;
      let totalWithDisc =
        totalWithQty -
        (totalWithQty / 100) *
          this.form.salesOrderItems[index].selections.discount;
      this.form.salesOrderItems[index].selections.adiscount = totalWithDisc;
      this.form.salesOrderItems[index].selections.amount = totalWithDisc;
      this.form.totalAmount = totalWithDisc;

      this.form.totalTax =
        (totalWithDisc / 100) *
        this.form.salesOrderItems[index].selections.percentage;
      this.form.salesOrderItems[index].selections.amount =
        this.form.salesOrderItems[index].selections.amount +
        (totalWithDisc / 100) *
          this.form.salesOrderItems[index].selections.percentage;
    },
    addProductSection() {
      this.form.salesOrderItems.push(this.orderBase);
      setTimeout(() => {
        this.setCustomEvents();
      }, 100);
    },
    removeProduct(index) {
      this.form.salesOrderItems.splice(index, 1);
    },
    setCustomEvents() {
      var elements = document.getElementsByClassName("vs__search");
      Array.from(elements).forEach(function (element) {
        element.addEventListener("focusin", () => {
          document
            .getElementsByClassName("v-data-table__wrapper")[0]
            .classList.remove("overflow-scroll");
          document
            .getElementsByClassName("v-data-table__wrapper")[0]
            .classList.add("overflow-visible");
        });
        element.addEventListener("focusout", () => {
          document
            .getElementsByClassName("v-data-table__wrapper")[0]
            .classList.add("overflow-scroll");
          document
            .getElementsByClassName("v-data-table__wrapper")[0]
            .classList.remove("overflow-visible");
        });
      });
    },
    varientSelected(value, type) {
      if (value) {
        if (type === 1) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient1",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Hardness added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getHardness();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 2) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient2",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Sharpness added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getSharpness();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 3) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient3",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Color added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getColor();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 4) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient4",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Shape added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getShape();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 5) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient5",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Number of faces added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getNumberofFaces();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 6) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient6",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Clarity added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getClarity();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 7) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient7",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Treatment added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getTreatment();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        } else if (type === 8) {
          this.showOverlay = true;
          this.addVarient({
            "url": "/Varient8",
            "data": {
              "name": value.name,
              "description": value.name
            }
          }).then(async () => {
            this.showOverlay = false;
              this.$bvToast.toast("Effect added successfully", {
                title: "Success",
                variant: "success",
                autoHideDelay: 5000,
              });
              await this.getEffect();
          }).catch(err => {
            this.showOverlay = false;
            console.log(err);
          })
        }
      }
    }
  },
  async mounted() {
    this.showOverlay = true;
    await this.setCustomEvents();
    await this.getHardness();
    await this.getSharpness();
    await this.getColor();
    await this.getShape();
    await this.getNumberofFaces();
    await this.getClarity();
    await this.getTreatment();
    await this.getEffect();
    await this.getPurchaseOrderNumber();
    await this.getCustomers("fields=&orderBy=&pageSize=30&skip=0&searchQuery=&customerName=&mobileNo=&phoneNo=&email=&contactPerson=");
    await this.getProducts(
      "fields=&orderBy=&pageSize=1000&skip=0&searchQuery=&name=&id=&categoryId=&unitId=&barcode=&brandId="
    );
    this.showOverlay = false;
  },
  watch: {
    expanded() {
      setTimeout(() => {
        this.setCustomEvents();
      }, 100);
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .overflow-scroll {
  overflow: scroll !important;
}
::v-deep .overflow-visible {
  overflow: visible !important;
}
.br-5 {
  border-radius: 5px;
}
.form-group {
  margin-top: 10px;
}
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
::v-deep.multi-select.select {
  width: 100%;
  display: block !important;
  .btn-select {
    width: 100%;
    text-align: left;
    .buttonLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
::v-deep .select2-selection {
  border: 1px solid #c6c6c6;
  height: calc(2.52438rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.01625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
::v-deep .select2-selection__rendered {
  padding: 0px !important;
  font-size: 1.01625rem;
  line-height: 1.5;
}
::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100% !important;
  position: absolute;
  top: 0 !important;
  right: 1px;
  width: 20px;
  bottom: 0;
}

::v-deep .vs__dropdown-toggle {
  height: calc(2.52438rem + 2px);
}

::v-deep .vs__open-indicator {
  transform: scale(0.5) !important;
}
::v-deep .vgt-responsive {
  overflow-x: visible !important;
}
::v-deep .table {
  background-color: transparent !important;
}
// ::v-deep table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   width: 100%;
//   table-layout: fixed;
// }

// ::v-deep table td {
//   text-align: left;
//   padding: 8px;
//   width: 200px;
//   border: 1px solid blue;
// }
// ::v-deep .vs__dropdown-menu {
//     position: fixed;
//     width:inherit;
// }
</style>
